<template>
	<div id="header" ref="header">
		<a-menu
			theme="light"
			mode="horizontal"
			v-model="active"
			:style="{ lineHeight: '64px', width: menuWidth }">
			<template v-if="!$store.state.menu.isInDashbord">
				<template v-for="item in menu">
					<a-menu-item
						:key="item.name"
						v-if="!withoutRouter.includes(item.name)">
						<router-link class="menu-item" :to="item.path">
							<!--<my-icon
                :type="item.meta.icon"
                :style="{ fontSize: '18px' }"
                v-if="item.meta.icon.includes('icon-')"
              />
              <a-icon :type="item.meta.icon" :style="{ fontSize: '18px' }" v-else />-->
							<span>{{ item.meta.title }}</span>
						</router-link>
					</a-menu-item>
				</template>
			</template>
		</a-menu>
		<!--高级版-->
		<template v-if="userInfo.shop && userInfo.shop.corpid">
			<a-dropdown
				:trigger="['click']"
				:getPopupContainer="getPopupContainer"
				v-if="userInfo.user && userInfo.user.avatar">
				<div ref="user-profile" class="user-profile">
					<!-- <div
            class="mobile-workbench mobile-workbench-first"
            @click.stop="goPackageOrder"
            v-if="$store.state.menu.isInDashbord"
          >
            <img alt="套餐订单" src="@/assets/packageOrder.svg" />
            <span>套餐订单</span>
          </div> -->
					<div
						class="mobile-workbench"
						@click.stop="mobileWorkbench"
						v-if="$store.state.menu.isInDashbord">
						<img alt="移动工作台" src="@/assets/wechat.png" />
						<span>移动工作台</span>
					</div>

					<a-badge
						:count="infoConfig.count"
						:offset="[-20, 0]"
						@click.stop="openInfoList">
						<img
							alt="消息"
							src="@/assets/infos.svg"
							class="infoImg"
							ref="infoImg" />
					</a-badge>

					<img
						class="user-head"
						:src="userInfo.user && userInfo.user.avatar"
						alt="用户头像" />
					<div class="user-name">
						<template v-if="userInfo.user && userInfo.user.name">
							{{ userInfo.user.name }}
						</template>
						<ww-open-data
							v-else
							type="userName"
							:openid="userInfo.user && userInfo.user.userid" />
					</div>
					<a-divider type="vertical"></a-divider>
					<div class="cursor-pointer" @click.stop="iconTap">
						<a-icon
							class="ml-4 text-sm"
							:type="
								$store.state.menu.isInDashbord
									? 'setting'
									: 'desktop'
							" />
						<span style="margin-left: 8px">
							{{
								$store.state.menu.isInDashbord
									? '设置'
									: '返回工作台'
							}}
						</span>
					</div>
				</div>
				<template slot="overlay">
					<a-menu>
						<a-menu-item class="logout">
							店铺ID ：{{ userInfo.shop && userInfo.shop.id }}
						</a-menu-item>
						<a-menu-item class="logout" @click="onSwitchStore">
							<img
								src="~@/assets/switch.png"
								:style="{
									width: '16px',
									height: '16px',
									marginRight: '5px'
								}"
								alt="" />
							切换门店
						</a-menu-item>
						<a-menu-item class="logout" @click="logout">
							<img
								src="~@/assets/logout.png"
								:style="{
									width: '16px',
									height: '16px',
									marginRight: '5px'
								}"
								alt="" />
							退出登录
						</a-menu-item>
					</a-menu>
				</template>
			</a-dropdown>
		</template>
		<!--普通版-->
		<template v-else>
			<a-dropdown
				:trigger="['click']"
				:getPopupContainer="getPopupContainer"
				v-model="dropdownStatus">
				<div class="user-profile" ref="user-profile2">
					<!-- <div
            class="mobile-workbench mobile-workbench-first"
            @click.stop="goPackageOrder"
            v-if="$store.state.menu.isInDashbord"
          >
            <img alt="套餐订单" src="@/assets/packageOrder.svg" />
            <span>套餐订单</span>
          </div> -->
					<div
						class="mobile-workbench"
						@click.stop="mobileWorkbench"
						v-if="$store.state.menu.isInDashbord">
						<img alt="移动工作台" src="@/assets/wechat.png" />
						<span>移动工作台</span>
					</div>

					<a-badge
						:count="infoConfig.count"
						:offset="[-20, 0]"
						@click.stop="openInfoList">
						<img
							alt="消息"
							src="@/assets/infos.svg"
							class="infoImg"
							ref="infoImg" />
					</a-badge>

					<img
						class="user-head"
						:src="
							(userInfo.shop && userInfo.shop.logo) ||
							'https://luyopay-statics.oss-cn-beijing.aliyuncs.com/default_avatar.png'
						"
						alt="用户头像" />
					<div class="user-name">
						{{
							(userInfo.shop && userInfo.shop.title) || '店铺名称'
						}}
					</div>
					<a-divider type="vertical"></a-divider>
					<div class="cursor-pointer" @click.stop="iconTap">
						<a-icon
							class="ml-4 text-sm"
							:type="
								$store.state.menu.isInDashbord
									? 'setting'
									: 'desktop'
							" />
						<span style="margin-left: 8px">
							{{
								$store.state.menu.isInDashbord
									? '设置'
									: '返回工作台'
							}}
						</span>
					</div>
				</div>
				<template slot="overlay">
					<a-menu>
						<a-menu-item class="logout">
							店铺ID ：{{ userInfo.shop && userInfo.shop.id }}
						</a-menu-item>
						<a-menu-item class="logout" @click="onSwitchStore">
							<img
								src="~@/assets/switch.png"
								:style="{
									width: '16px',
									height: '16px',
									marginRight: '5px'
								}"
								alt="" />
							切换门店
						</a-menu-item>
						<a-menu-item class="logout" @click="logout">
							<img
								src="~@/assets/logout.png"
								:style="{
									width: '16px',
									height: '16px',
									marginRight: '5px'
								}"
								alt="" />
							退出登录
						</a-menu-item>
					</a-menu>
				</template>
			</a-dropdown>
		</template>

		<!-- S 消息提示 查看短信消息 -->
		<a-tooltip
			placement="bottomLeft"
			trigger="click"
			overlayClassName="infoConfigBox"
			@click.stop="dropdownStatus = false"
			:getPopupContainer="getPopupContainer"
			:autoAdjustOverflow="true">
			<div class="tooltipBox"></div>
			<!-- /img.info 信息提示图标 -->

			<template slot="title">
				<div class="infoEdit">
					<a-button @click="clearInfoList">清空通知</a-button>
					<span>|</span>
					<a-button @click="goInfomation">查看更多</a-button>
				</div>
				<!-- 操作 -->

				<div class="infoTables">
					<a-radio-group
						v-model="infoConfig.readStatus"
						size="large"
						@change="getInfoList">
						<a-radio-button value="0"
							>未读({{ infoConfig.unread }})</a-radio-button
						>
						<a-radio-button value="1"
							>已读({{ infoConfig.read }})</a-radio-button
						>
					</a-radio-group>
					<!-- /tabs 切换已读未读 -->

					<a-spin :spinning="infoConfig.spinning">
						<ul class="infoList">
							<div
								class="noInfoData"
								v-if="
									infoConfig.data.length === 0 &&
									infoConfig.readStatus === '0'
								">
								全部已读～
							</div>
							<div
								class="noInfoData"
								v-if="
									infoConfig.data.length === 0 &&
									infoConfig.readStatus === '1'
								">
								还没有消息哦～
							</div>
							<li
								v-for="(item, index) in infoConfig.data"
								:key="index">
								<img :src="item.imgSrc" />
								<div class="infoContent">
									<p>{{ item.info }}</p>
									<span class="infoTime">{{
										item.time
									}}</span>
								</div>
							</li>
						</ul>
					</a-spin>
					<!-- /list 列表 -->
				</div>
			</template>
			<!-- /list 内容 -->
		</a-tooltip>
		<!-- E 消息提示 查看短信消息 -->

		<a-modal
			ref="passwordVisible"
			dialog-class="modal-content"
			width="600px"
			title="输入操作密码"
			:mask-closable="false"
			v-model="passwordVisible"
			:confirmLoading="passwordBtnLoading"
			:afterClose="passwordAfterClose"
			@cancel="passwordVisibleClose"
			@ok="confirm">
			<a-form-model
				ref="password"
				:model="passwordForm"
				:label-col="{ span: 6 }"
				:wrapper-col="{ span: 18 }">
				<a-row type="flex" justify="center">
					<a-col :span="19">
						<a-form-model-item
							label="操作密码"
							prop="password"
							:rules="{
								required: true,
								message: '请输入操作密码'
							}">
							<a-input
								v-model.trim="passwordForm.password"
								placeholder="请输入操作密码"
								type="password"
								@keydown.enter="confirm" />
						</a-form-model-item>
					</a-col>
					<a-col :span="5">
						<a-button
							type="link"
							class="forgetBtn"
							@click="forgetPassowrd"
							>忘记密码?</a-button
						>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>

		<!-- 重置操作密码 -->
		<a-modal
			ref="passwordVisible"
			dialog-class="reset_modal_content"
			width="680px"
			title="修改操作密码"
			:mask-closable="false"
			v-model="resetPasswordVisible"
			:confirmLoading="resetBtnLoading"
			:afterClose="passwordAfterClose"
			@cancel="closeReset"
			@ok="resetEditPassword">
			<a-form-model
				ref="resetPassword"
				class="resetFormBox"
				:model="resetForm"
				:label-col="{ span: 5 }"
				:wrapper-col="{ span: 18 }">
				<a-form-model-item label="管理员手机号">
					<!-- <span>{{ $store.state.user? $store.state.user.info.user.phone : userInfo.phone }}</span> -->
					<span>{{
						userInfo.phone
							? userInfo.phone
							: $store.state.user.info.user.phone
					}}</span>
				</a-form-model-item>

				<a-form-model-item
					label="验证码"
					prop="verify"
					:rules="{ required: true, message: '请输入验证码' }"
					style="margin-bottom: 24px !important">
					<a-row style="margin-buttom: 10px">
						<a-col :span="15">
							<a-input
								placeholder="验证码"
								v-model="resetForm.verify"
								type="number" />
						</a-col>
						<a-col :span="5">
							<div
								class="button"
								@click="getCode"
								v-if="!isGetCode">
								{{ btnText }}
							</div>
							<div class="button_disabled" v-else>
								{{ downTime }}s
							</div>
						</a-col>
					</a-row>
				</a-form-model-item>

				<a-form-model-item
					label="新密码"
					prop="password"
					:rules="{ required: true, message: '请输入新密码' }">
					<a-input-password
						placeholder="请输入新密码（6位数字）"
						:max-length="6"
						v-model="resetForm.password" />
				</a-form-model-item>

				<a-form-model-item
					label="确认密码"
					prop="confirmPassword"
					:rules="{ required: true, message: '请再次输入新密码' }">
					<a-input-password
						placeholder="请再次输入新密码（6位数字）"
						:max-length="6"
						v-model="resetForm.confirmPassword" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
// import Vue from "vue";
import menu from '@/config/topMenu'
import { mapGetters, mapState } from 'vuex'
import storage from 'store'
import { USER_INFO } from '@/utils/request'
import {
	clearPassword,
	validatePassword,
	shopResetPassword
} from '@/api/dashboard'
import {
	getVerifyCode,
	noticeSmsList,
	noticeSmsSetAllRead
} from '@/api/Official'
import { menuNeedObj } from '@/utils/utils'
import { Modal } from 'ant-design-vue'
import moment from 'moment'

export default {
	name: 'Header',
	data() {
		return {
			withoutRouter: [
				'Index',
				'BookReview',
				'Information',
				'PackageOrder',
				'StoreList'
			],
			menu,
			passwordVisible: false,
			//密码输入正确跳转路径
			passwordPath: 'Store',
			passwordForm: {
				password: ''
			},
			passwordBtnLoading: false,
			menuWidth: '',

			resetPasswordVisible: false,
			resetBtnLoading: false,
			resetPhone: '',
			resetForm: {
				verify: '', // 验证码
				password: '', // 新密码
				confirmPassword: '' // 再次输入密码
			},
			downTime: 60, // 验证码倒计时
			isGetCode: false, //是否已经获取了验证码
			btnText: '获取验证码',

			dropdownStatus: false, // dropdown下来开启状态
			btnTooltip: false, // tooltip按钮状态

			// 消息对象
			infoConfig: {
				count: 0,
				spinning: false,
				readStatus: '0',
				unread: 0,
				read: 0,
				unreadSrc: require('@/assets/unread.svg'),
				readSrc: require('@/assets/read.svg'),
				unreadList: [],
				readList: [],
				data: []
			},

			getInfoTimer: null,

			tooltopClass: false
		}
	},
	watch: {
		'$route.name': {
			immediate: true,
			handler(val) {
				this.$store.commit(
					'menu/setIsInDashbord',
					this.withoutRouter.includes(val)
				)
				if (this.$store.state.menu.isInDashbord) return
				this.$nextTick(() => {
					let profileWidth = (
						this.$refs['user-profile'] ||
						this.$refs['user-profile2']
					).clientWidth
					if (profileWidth) {
						this.menuWidth = `calc(100vw - ${profileWidth + 225}px)`
					}
				})
			}
		},
		infoChange() {
			this.getInfoList()
		}
	},
	async mounted() {
		let userInfo = storage.get(USER_INFO)

		if (userInfo) {
			this.$store.commit('user/SET_INFO', userInfo)
		} else {
			await this.$store.dispatch('user/GetInfo')
		}

		//根据是否有企业ID设置顶部菜单
		if (userInfo && !userInfo.shop.corpid) {
			//普通版
			this.menu = this.menu.filter(
				item => item.meta && !item.meta.isNeedCorpid
			)

			this.resetPhone = userInfo.phone
		} else {
			//企业微信注册
			this.menu = this.menu.filter(item => !item.meta.isNormal)
		}

		//eventBus  开启弹窗 带跳转路径
		this.$EventBus.$on('openPasswordVisible', path => {
			this.passwordPath = path
			this.passwordVisible = true
		})

		// 初始化-获取短信列表
		this.getInfoList()

		document.addEventListener('click', this.documentClick, false)
	},
	destroyed() {
		// 离开时清除计步器
		if (this.getInfoTimer) {
			clearInterval(this.getInfoTimer)
			this.getInfoTimer = null
		}
		document.removeEventListener('click', this.documentClick)
	},
	methods: {
		// 获取验证码
		getCode() {
			if (this.isGetCode) {
				return
			}

			this.getCodeAsync(this.$store.state.user?.info?.user?.phone)
		},

		// 请求获取验证码
		getCodeAsync(phone = '') {
			getVerifyCode({ phone, type: 2 }).then(res => {
				if (res.code == '0') {
					this.isGetCode = true
					let downTime = 60
					let intervalID = setInterval(() => {
						downTime--
						if (downTime <= 0) {
							clearInterval(intervalID)
							this.isGetCode = false
							this.btnText = '重新获取'
						}
						this.downTime = downTime
					}, 1000)
				}
			})
		},

		logout() {
			this.$confirm({
				title: '退出登录',
				content: '确认要退出登录吗？',
				onOk: () => {
					this.$store.dispatch('user/Logout')
				}
			})
		},
		/**
		 * @description: 切换门店
		 * @return {*}
		 */
		onSwitchStore() {
			this.$router.push({ name: 'StoreList' })
			this.dropdownStatus = false
		},
		iconTap() {
			this.btnTooltip = false
			this.dropdownStatus = false
			if (this.$store.state.menu.isInDashbord) {
				this.passwordPath = 'Store'
				this.passwordVisible = true
			} else {
				clearPassword().then(res => {
					if (res.code === 0) {
						this.$router.push({ name: 'Index' })
					}
				})
			}
		},
		passwordVisibleClose() {
			this.$refs.password.clearValidate()
			this.passwordVisible = false
			this.passwordPath = 'Store'
			this.passwordForm = {}
		},
		passwordAfterClose() {
			document.body.style = null
		},
		confirm() {
			this.$refs.password
				.validate()
				.then(() => {
					this.passwordBtnLoading = true
					validatePassword(this.passwordForm)
						.then(res => {
							if (res.code === 0 && res.data.auth) {
								this.$message.success('验证成功！')
								this.$router.push({ name: this.passwordPath })
								this.passwordVisibleClose()
							} else {
								this.$message.error('验证失败！')
							}
							this.passwordBtnLoading = false
						})
						.catch(() => (this.passwordBtnLoading = false))
				})
				.catch(err => console.log(err))
		},
		mobileWorkbench() {
			this.btnTooltip = false
			this.passwordVisible = true
			this.passwordPath = 'MobileWorkbench'
		},
		getPopupContainer() {
			return document.getElementById('header')
		},

		// 忘记密码
		forgetPassowrd() {
			// 1、关闭输入操作密码弹框
			this.passwordVisibleClose()

			// 2、打开提示
			// Modal.confirm({
			//   width: "600px",
			//   icon: () =>
			//     new Vue().$createElement("a-icon", {
			//       props: { type: "info-circle" },
			//       style: "color:#e63e30",
			//     }),
			//   title: "提示",
			//   content: `您的新密码将通过短信方式发送给管理员，手机号为${this.$store.state.user?.info?.user?.phone}。`,
			//   okText: "确定",
			//   cancelText: "取消",
			//   onOk: () => {
			//     return this.resetEditPassword()
			//   }
			// });

			// 2、打开修改密码弹框
			this.resetPasswordVisible = true
		},

		// 重置操作密码
		resetEditPassword() {
			// return shopResetPassword({
			//   phone: this.$store.state.user?.info?.user?.phone,
			//   type: 2
			// }).then((res) => {
			//   if (res.code !== 1) return false
			//   Modal.success({
			//     title: "提示",
			//     content: "您的密码通过短信方式成功发送给管理员！"
			//   })
			// })
			let _this = this

			this.$refs.resetPassword.validate().then(() => {
				this.resetBtnLoading = true
				let params = menuNeedObj(this.resetForm, {
					password: 'password',
					re_password: 'confirmPassword',
					verify: 'verify'
				})

				params.phone = this.$store.state.user.info.user.phone

				shopResetPassword(params)
					.then(res => {
						if (res.code === 0) {
							this.closeReset()
							Modal.success({
								title: '提示',
								content: '您的密码重置成功',
								onOk: () => {
									_this.iconTap()
								}
							})
						} else {
							this.$message.error('重置失败！')
						}
						this.resetBtnLoading = false
					})
					.finally(() => {
						this.resetBtnLoading = false
					})
			})
		},

		// 关闭重置密码弹窗
		closeReset() {
			this.$refs.resetPassword.clearValidate()
			this.resetPasswordVisible = false
			this.resetBtnLoading = false
			this.resetForm = {}
		},

		// 获取短信列表
		// 进入页面，读取两组数据，每15秒读取一次
		getInfoList() {
			const getList = () => {
				// 开启loading
				this.infoConfig.spinning = true

				let unreadPro = noticeSmsList({ status: 0 })
				let readPro = noticeSmsList({ status: 1 })
				Promise.all([unreadPro, readPro])
					.then(resList => {
						let unreadRes = resList[0].data
						let readRes = resList[1].data

						// 设置通知总数 = 未读总数
						this.infoConfig.count = unreadRes.total || 0

						// 设置分类数
						this.infoConfig.unread = unreadRes.total
						this.infoConfig.read = readRes.total

						// 设置内容
						this.infoConfig.unreadList = (unreadRes.data || []).map(
							item => ({
								imgSrc: this.infoConfig.unreadSrc,
								time: item.timestamp
									? moment(item.timestamp * 1000).format(
											'YYYY-MM-DD HH:mm:ss'
									  )
									: '--',
								info: `${item.address}回复：“${item.content}”`
							})
						)
						this.infoConfig.readList = (readRes.data || []).map(
							item => ({
								imgSrc: this.infoConfig.readSrc,
								time: item.timestamp
									? moment(item.timestamp * 1000).format(
											'YYYY-MM-DD HH:mm:ss'
									  )
									: '--',
								info: `${item.address}回复：“${item.content}”`
							})
						)

						this.infoConfig.data =
							this.infoConfig.readStatus === '0'
								? this.infoConfig.unreadList.slice(0, 5)
								: this.infoConfig.readList.slice(0, 5)
					})
					.finally(() => {
						// 关闭loading
						this.infoConfig.spinning = false
					})
			}
			getList() // 进入页面第一次请求

			// 如果有计步器，清空计步器
			if (this.getInfoTimer) {
				clearInterval(this.getInfoTimer)
				this.getInfoTimer = null
			}
			this.getInfoTimer = setInterval(getList, 5 * 60 * 1000)
		},

		// 清空消息列表
		clearInfoList() {
			if (this.infoConfig.count === 0) return false

			// 开启loading
			this.infoConfig.spinning = true

			noticeSmsSetAllRead()
				.then(res => {
					if (res.code === 0)
						this.$message.success(res.message || '操作成功')

					// 切换到消息已读状态
					this.infoConfig.readStatus = '1'

					// 重新刷新消息接口
					this.getInfoList()
				})
				.finally(() => {
					// 关闭loading
					this.infoConfig.spinning = false
				})
		},

		// 跳转到消息详情
		goInfomation() {
			this.$refs.infoImg.click() // 关闭提示
			this.$router.push({ name: 'Information' })
		},

		// 跳转到套餐订单
		goPackageOrder() {
			this.$router.push({ name: 'PackageOrder' })
		},

		// 打卡消息弹框
		openInfoList() {
			this.$nextTick(() => {
				if (this.btnTooltip) this.btnTooltip = false
				else {
					// 关闭
					this.dropdownStatus = false

					document.querySelector('.tooltipBox').click()
					this.btnTooltip = true
				}
			})
		},

		// documentClick
		documentClick() {
			this.btnTooltip = false
		}
	},
	computed: {
		...mapState('user', { userInfo: 'info', infoChange: 'infoChange' }),
		...mapGetters('menu', { active: 'formatSubMenu' })
	}
}
</script>

<style scoped lang="less">
@charset "UTF-8";

.resetFormBox {
	overflow: hidden;
	::v-deep {
		.ant-input,
		.ant-form-item {
			height: 42px;
		}
	}
	.button {
		width: 146px;
		height: 42px;
		background: #ffffff;
		border: 1px solid #e43937;
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		color: #e43937;
		text-align: center;
		line-height: 42px;
		margin-left: 9px;
		cursor: pointer;
	}

	.button_disabled {
		width: 146px;
		height: 42px;
		background: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		color: #999999;
		text-align: center;
		line-height: 42px;
		margin-left: 9px;
	}
}

.menu-item {
	display: flex !important;
	align-items: center;
}

#header {
	& ::v-deep .ant-menu {
		font-size: 16px;
	}
}

.user-head {
	width: 38px;
	height: 38px;
	object-fit: cover;
	border-radius: 50%;
	cursor: pointer;
}

.user-profile {
	width: fit-content;
	max-width: 500px;
	height: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: flex-end;
	right: 22px;
	align-items: center;
	font-size: 14px;
	color: #333333;
	line-height: 22px;
	font-weight: bold;
	user-select: none;
}

.user-name {
	margin-left: 8px;
	cursor: pointer;
	max-width: 260px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.cursor-pointer {
	width: 113px;
}

.user-profile-line {
	width: 1px;
	height: 16px;
	background: #ebebeb;
	margin: 0 20px;
}

.logout {
	display: flex;
	align-items: center;
	padding: 10px 14px;
	font-size: 14px;
}

.store-name {
	max-width: 15em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mobile-workbench-first {
	left: -160px !important;
	span {
		left: 44px !important;
	}
}

.mobile-workbench {
	position: absolute;
	left: -20px;
	transform: translateX(-100%);
	width: fit-content;
	cursor: pointer;
	//margin-right: 20px;
}

.mobile-workbench > img {
	width: 120px;
	height: auto;
}

.mobile-workbench > span {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	color: #333333;
	z-index: 1;
	left: 40px;
}

.forgetBtn {
	height: 40px;
	font-weight: 400;
	font-size: 16px;
	color: #e63e30;
}

// 消息图片icon
.infoImg {
	height: 24px;
	width: 24px;
	margin-right: 20px;
}

.tooltipBox {
	position: absolute;
	top: 20px;
	right: 477px;
	height: 24px;
	width: 24px;
	z-index: -1;
}

::v-deep .infoConfigBox {
	padding-top: 22px;
	.ant-tooltip-arrow {
		display: none;
	}
	.ant-tooltip-inner {
		box-sizing: border-box;
		width: 480px;
		max-height: 511px;
		overflow: hidden;
		background-color: #fff;
		border-radius: 4px;
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-start;
		padding: 0px;

		// 内容部分
		.infoTables {
			flex: 1;
			padding: 20px 20px 0;
			// 标题部分
			.ant-radio-group {
				width: 100%;
				height: 44px;
				display: flex;
				align-items: center;
				text-align: center;
				font: normal 400 16px/44px 'PingFangSC-Regular';
				border: 1px solid #d9d9d9;
				border-radius: 6px;
				.ant-radio-button-wrapper {
					flex: 1;
					border: none;
					color: #999;
				}
				.ant-radio-button-wrapper-checked {
					border: none;
					color: #333;
					box-shadow: none;
				}
				.ant-radio-button-wrapper-checked::before {
					display: none;
				}
			}

			// 列表部分
			.infoList {
				margin-top: 15px;
				max-height: 377px;
				min-height: 95px;
				overflow: hidden;
				.noInfoData {
					font: 400 14px/14px 'PingFangSC-Regular';
					margin-top: 95px * 0.3;
					margin-left: 171px;
					color: #666666;
				}
				li {
					width: 100%;
					height: 72px;
					border-bottom: 1px solid #e6e6e6;
					padding: 15px 0;
					display: flex;
					align-items: flex-start;
					img {
						width: 42px;
						height: 42px;
						margin-right: 9px;
					}
					.infoContent {
						width: 390px;
						color: #333;
						font: 500 16px/18px 'PingFangSC-Medium';
						.infoTime {
							font: 400 14px/14px 'PingFangSC-Regular';
							color: #666;
							margin-top: 6px;
							display: block;
						}
					}
				}
				li:last-child {
					border-bottom: none;
				}
			}
		}

		// 操作部分
		.infoEdit {
			width: 100%;
			height: 55px;
			border-top: 1px solid #e6e6e6;
			display: flex;
			align-items: center;
			color: #e6e6e6;
			.ant-btn {
				flex: 1;
				height: 100%;
				font: 500 16px/18px 'PingFangSC-Regular';
				color: #333;
				border: none;
			}
		}
	}
}
</style>
